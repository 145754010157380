<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import AgentAutoComplete from '@/components/agent-auto-complete-input'
import AddressAutoComplete from '@/components/address-auto-complete-input'

import Choices from "choices.js";

import {
    required,
} from "vuelidate/lib/validators";

import {getPMApi} from '@/api/pm'



/**
 * Setup PM  component
 */
export default {
  page: {
    title: "PM",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },

  validations: {
        agent_name : {required},
        landlord_name : {required},
        pma_code_suffix : {required}
  },


  methods: {

    formSubmit() {
    
      this.$v.$touch();
      if (this.$v.$invalid == false) {

        let landlord = {
          agent_id               : this.agent_id,
          landlord_name          : this.landlord_name,
          is_non_resident        : this.is_non_resident? 1 : 0,
          is_resident            : this.is_non_resident? 0 : 1,
          landlord_address       : this.landlord_address,
          owner_code             : this.pma_code_prefix+this.pma_code_suffix,
          phone1        : this.phone1,
          phone2        : this.phone2,
          phone3        : this.phone3,

          email1        : this.email1,
          email2        : this.email2,
          email3        : this.email3,

          account_name  : this.account_name,
          institution   : this.institution,
          transit       : this.transit,
          account       : this.account,
          rent_type     : this.rent_type,
        }

        getPMApi().add_landlord(landlord).then(res => {
          if (res.errCode == 0) {
            this.$alertify.message("Landlord is added" );
            this.$router.push({name : 'pm-landlord-list'})
          } else {
            this.$alertify.error("Landlord add failed:" + res.errCode );
          }
        })
      }

    },


    onSelectedAgent(agent) {
      this.agent_id = agent.agent.id
      this.agent_name = agent.agent.first_name + ' '+agent.agent.last_name,
      this.pma_code_prefix = agent.agent.property_mgt_code
    },


    onLandlordAddressSelected(evt) {
      this.landlord_address = evt.full
    },

   
   
  },

  data() {
    return {
      title: "PM",
      items: [
        {
          text: "Owner List",
          href: "/pm/lanlord_list",
        },
        {
          text: "Add Owner",
          active: true,
        },
      ],



      agent_id : '',
      agent_name : '',
      landlord_name : '',
      landlord_address : '',

      phone1 : '',
      phone2 : '',
      phone3 : '',
      email1: '',
      email2: '',
      email3 : '',

      account_name : '',
      institution: '',
      transit : '',
      account : '',
      is_non_resident : false,
      rent_type : 'EFT',
      pma_code_prefix : '',
      pma_code_suffix : '',
    };
  },
  components: {
    Layout,
    PageHeader,
    AgentAutoComplete,
    AddressAutoComplete,

    
  },
  mounted() {
    new Choices("#rent_type", {
      removeItemButton: false,
    });
  },

  watch: {


  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">

      <div class="col-xxl-12 col-lg-12">
        <div class="card">
          <div class="card-body">
            <h5 class="card-title mb-4">Basic Info</h5>
            <form class="needs-validation" @submit.prevent="formSubmit">

              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        01
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">General Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>
                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Agent</label>
                          <AgentAutoComplete
                                    v-model="agent_name"
                                    @onSelected="onSelectedAgent"
                                    autocomplete="off"
                                    :init_data="{style_class : {'is-invalid':$v.agent_name.$error}, agent_name : agent_name}"
                                />
                          <div v-if="$v.agent_name.$error" class="invalid-feedback">
                              <span v-if="!$v.agent_name.required">This value is required.</span>
                          </div>

                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Owner Name</label>
                          <input type="text" class="form-control" v-model="landlord_name" :class="{'is-invalid':$v.landlord_name.$error}" />
                         
                          <div v-if="$v.landlord_name.$error" class="invalid-feedback">
                              <span v-if="!$v.landlord_name.required">This value is required.</span>
                          </div>

                        </div>
                      </div>

                      
                      <div class="form-check mt-4 mb-3 col-lg-3">
                        <input
                          type="checkbox"
                          class="form-check-input"
                          id="formrow-customCheck"
                          v-model="is_non_resident"
                         
                        />
                        <label class="form-check-label" for="formrow-customCheck"  >Non Resisent?</label
                        >
                      </div>
                      

                    </div>


                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Phone1</label>
                          <input type="text" class="form-control" v-model="phone1"  />

                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Phone2</label>
                          <input type="text" class="form-control" v-model="phone2"  />
                         
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Phone3</label>
                          <input type="text" class="form-control" v-model="phone3"  />
                         
                        </div>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Email 1</label>
                          <input type="text" class="form-control" v-model="email1"  />

                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Email 2</label>
                          <input type="text" class="form-control" v-model="email2"  />
                         
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Email 3</label>
                          <input type="text" class="form-control" v-model="email3"  />
                         
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-9">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Landlord Address</label>
                          <AddressAutoComplete
                              v-model="landlord_address"
                             
                              @onSelected="onLandlordAddressSelected($event)"
                              autocomplete="off"
                              />
                          
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">PMA Code</label>
                          <input type="text" class="form-control" v-model="pma_code_prefix" readonly />

                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">PMA Code Number</label>
                          <input type="text" class="form-control" v-model="pma_code_suffix" :class="{'is-invalid':$v.pma_code_suffix.$error}"   />
                          <div v-if="$v.pma_code_suffix.$error" class="invalid-feedback">
                              <span v-if="!$v.pma_code_suffix.required">This value is required.</span>
                          </div>
                          
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- end card -->




              <div class="card border shadow-none mb-5">
                <div class="card-header d-flex align-items-center">
                  <div class="flex-shrink-0 me-3">
                    <div class="avatar-sm">
                      <div class="avatar-title rounded-circle bg-soft-primary text-primary">
                        02
                      </div>
                    </div>
                  </div>
                  <div class="flex-grow-1">
                    <h5 class="card-title">Bank Info</h5>
                  </div>
                </div>
                <div class="card-body">
                  <div>

                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Rent Type</label>
                          <select id="rent_type" v-model="rent_type">
                            <option value="EFT" selected>EFT</option>
                            <option value="CHEQUE" selected>Cheque</option>
                          </select>
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label for="workexperience-companyname-input">Account Name</label>
                          <input type="text" class="form-control" v-model="account_name" :disabled="this.rent_type == 'CHEQUE'" />
                        </div>
                      </div>
                    </div>


                    <div class="row">
                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Institution </label>
                          <input type="text" class="form-control" v-model="institution"  :disabled="this.rent_type == 'CHEQUE'"/>

                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Transit</label>
                          <input type="text" class="form-control" v-model="transit"  :disabled="this.rent_type == 'CHEQUE'"/>
                         
                        </div>
                      </div>

                      <div class="col-lg-4">
                        <div class="mb-3">
                          <label class="form-label" for="gen-info-name-input">Account</label>
                          <input type="text" class="form-control" v-model="account" :disabled="this.rent_type == 'CHEQUE'" />
                         
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
              <!-- end card -->



              <div class="row">
                <div class="col-md-12">
                  <div class="d-flex flex-wrap gap-2 justify-content-end d-flex align-items-right">
                    <button type="button" class="btn btn-secondary w-sm" @click="$router.push({name: 'pm-landlord-list'})"> Cancel </button>
                    <button type="submit" class="btn btn-primary w-sm">
                      Add Owner & Property
                    </button>
                  </div>
                </div>
              </div>
            </form>
            <!-- end form -->
          </div>
          <!-- end card body -->
        </div>
        <!-- end card -->
      </div>
      <!-- end col -->
    </div>
    <!-- end row -->
  </Layout>
</template>

<style scoped>
@import "~choices.js/public/assets/styles/choices.min.css";
</style>
